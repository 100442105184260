<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    :width="isFullScreen ? '80vw' : ''"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Upload Member Data 

          <b-form-checkbox
                      v-model="isFullScreen"
                      name="check-button"
                      switch
                      inline
                      v-b-tooltip.hover.top="'Enable Fullscreen Mode'"
                    >
                      
          </b-form-checkbox>
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="File "
            rules="required"
          >
            <b-form-group
              label="Select / Drag and Drop file"
              label-for="file"
            >
              <b-form-file
                id="file"
                v-model="memberData.file"
                autofocus
                :state="getValidationState(validationContext)"
                accept=".xls, .xlsx, .csv"
                placeholder="Select Excel / CSV Document"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div variant="secondary">
              <div class="alert-body">
                  <p>To upload member Data, follow instructions below</p>
                  <i>Format your <span class="text-primary">Excel Columns (.xls, .xlsx / .csv)</span> as follows; </i>
                  <!-- <br><i><b-badge style="cursor: pointer" @click="copyTable" variant="primary">copy</b-badge> table into <span class="text-primary">Excel </span></i> -->
                  <br>
                  <b-table ref="memberTable" class="mt-2" responsive :fields="tableFields" :items="items">
                  </b-table>
                  <ul>
                    <li> <span class="text-primary">ID - <i class="text-secondary">leave blank to autogenerate / fill to set to manual</i> </span></li>
                    <li> <span class="text-primary">FULLNAME - <i class="text-secondary">firstname othernames surname</i></span></li>
                    <li> <span class="text-primary">DOB</span></li>
                    <li> <span class="text-primary">DAYBORN - <i class="text-secondary">sunday, monday, tuesday, wednesday, thursday, friday, saturday</i></span></li>
                    <li> <span class="text-primary">GENDER - <i class="text-secondary">male, female</i> </span></li>
                    <li> <span class="text-primary">COUNTRY_CODE</span></li>
                    <li> <span class="text-primary">CONTACT1</span></li>
                    <li> <span class="text-primary">EMAIL</span></li>
                    <li> <span class="text-primary">NATIONALITY</span></li>
                    <li> <span class="text-primary">MARITAL_STATUS - <i class="text-secondary">single, married, divorced, seperated, widow / widower</i> </span></li>
                    <li> <span class="text-primary">MEMBERSHIP_TYPE  - <i class="text-secondary">active, not active, distant, deceased, invalid </i> </span></li>
                    <li> <span class="text-primary">ADDRESS</span></li>
                  </ul>
                  
                  <p><b class="text-primary">*NB:</b> Excel file should not be more than <b class="text-primary">1MB</b> or <b class="text-primary">20,000</b> records per upload.</p>
              </div>
            </div>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormFile, BTable, BFormCheckbox, VBTooltip, BBadge
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

//
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    BTable,
    BFormCheckbox,
    BBadge
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
  setup(props, { emit }) {
    const blankMemberData = {}
    // Use toast
    const toast = useToast()

    const tableFields =  ['ID', 'FULLNAME', 'DOB', 'DAYBORN', 'GENDER', 'COUNTRY_CODE', 'CONTACT1',  'EMAIL', 'NATIONALITY',  'MARITAL_STATUS', 'MEMBERSHIP_TYPE', 'ADDRESS' ]
    const items = [
      {
        ID: '001', FULLNAME: 'Kofi Asare', DOB: '1989/06/01', GENDER: 'male', COUNTRY_CODE: '+233', CONTACT1: '240000000', EMAIL: 'kofi.asare@email.com', NATIONALITY: 'ghanaian', DAYBORN: 'thursday', MARITAL_STATUS: 'single', MEMBERSHIP_TYPE: 'active', ADDRESS: ''
      },
      {
        ID: '002', FULLNAME: 'Ama Owusua', DOB: '1994/04/20', GENDER: 'female', COUNTRY_CODE: '+233', CONTACT1: '260000000', EMAIL: '', NATIONALITY: 'ghanaian', DAYBORN: 'wednesday', MARITAL_STATUS: 'single', MEMBERSHIP_TYPE: 'distant', ADDRESS: ''
      },
      {
        ID: '002', FULLNAME: 'Maame Dokua', DOB: '1980/11/08', GENDER: 'female', COUNTRY_CODE: '+233', CONTACT1: '250000000', EMAIL: '', NATIONALITY: 'ghanaian', DAYBORN: 'wednesday', MARITAL_STATUS: 'married', MEMBERSHIP_TYPE: 'active', ADDRESS: ''
      }
    ]

    const isFullScreen = ref(false);

    const copyTable = () => {
      
    }

    const memberData = ref(blankMemberData)
    const resetmemberData = () => {
      memberData.value = (blankMemberData)
    }

    const onSubmit = () => {
      store.dispatch('app-member/uploadMemberData', memberData.value)
        .then((response) => {

          toast({
            component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: 'CheckIcon',
                variant: 'success',
              },
          })

          emit('fetchMembers')
          emit('getMembers')
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)

          if (response.data.err.length > 0) {
            //
            response.data.err.forEach((v)=>{
              //
              toast({
                component: ToastificationContent,
                props: {
                  title: v.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
          }

          resetmemberData()

        }).catch(err=>{
          toast({
          component: ToastificationContent,
          props: {
            title: err.response.data,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetmemberData)

    return {
      memberData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      tableFields,
      items,
      isFullScreen,
      copyTable
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
