<template>

  <div>

    <member-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewMemberSidebarActive"
      @refetch-data="refetchData"
      @fetchMembers="fetchMembers"
      @getMembers="getMembers"
    />

    <!-- Filters -->
    <members-list-filters
      :dayborn-filter.sync="daybornFilter"
      :gender-filter.sync="genderFilter"
      :marital-filter.sync="maritalFilter"
      :status-filter.sync="statusFilter"
      :dayborn-options="daybornOptions"
      :gender-options="genderOptions"
      :marital-options="maritalOptions"
      :status-options="statusOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                variant="primary"
                tag="router-link"
                :to="{ name: 'apps-members-add' }"
                class="mr-2"
              >
                <span class="text-nowrap">Add Member</span>
              </b-button>
              <b-button
                variant="outline-primary"
                @click="isAddNewMemberSidebarActive = true"
              >
                <span class="text-nowrap">Upload Members</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refMemberListTable"
        class="position-relative"
        :items="getMembers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
      <!-- Column: menID -->
      <template #cell(memID)="data">
        <b-link
          :to="{ name: 'apps-members-view', params: { id: data.item.id }}"
          class="font-weight-bold text-secondary"
        >
          #{{ data.item.memID }}
        </b-link>
      </template>

        <!-- Column: Name -->
        <template #cell(fullName)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveMemberRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-members-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-members-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.fullName }}
            </b-link>
            <small class="text-muted">@{{ data.item.email }}</small>
          </b-media>
        </template>

        <!-- Column: Contact -->
      <template #cell(contact1)="data">
        <b-link
          :href="`tel:${data.item.contact1.countryCode+data.item.contact1.tel}`"
          class="font-weight-bold"
        >
          {{ data.item.contact1.countryCode+data.item.contact1.tel }}
        </b-link>
        
        <b-link
          v-if="data.item.contact2"
          :href="`tel:${data.item.contact2.countryCode+data.item.contact2.tel}`"
          class="font-weight-bold mt-2"
        >
        ,  {{ data.item.contact2.countryCode+data.item.contact2.tel }}
        </b-link>
      </template>

        <!-- Column: Role -->
        <template #cell(gender)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveMemberRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              
            />
            <b-badge :variant="`${resolveMemberGenderVariant(data.item.gender)}`">{{ resolveGenderVariant(data.item.gender) }}</b-badge>
            <!-- <span class="align-text-top text-capitalize">{{ data.item.role }}</span> -->
          </div>
        </template>

        <!-- Column: DOB -->
        <template #cell(dob)="data">
          <div class="text-dark">
            {{  new Date(data.item.dob).toGMTString().substring(5,16) }}
          </div>
        </template>

        <!-- Column: Dayborn -->
        <template #cell(dayborn)="data">
          <div class="text-dark">
            {{ resolveDaybornVariant(data.item.dayborn) }}
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(membershipType)="data">
          <b-badge
            pill
            :variant="`light-${resolveMemberStatusVariant(data.item.membershipType)}`"
            class="text-capitalize"
          >
            {{ data.item.membershipType }}
          </b-badge>
          <span class="ml-1 font-weight-bold small" v-if="data.item.dateDied">{{ data.item._dateDied }}</span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item>
              <feather-icon icon="MailIcon" />
              <span class="align-middle ml-50">Send Message</span>
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'apps-members-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-members-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalMembers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import router from '@/router'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import MembersListFilters from './MemberListFilters.vue'
import useMemberList from './useMemberList'
import memberStoreModule from '../memberStoreModule'
import MemberListAddNew from './MemberListAddNew.vue'

export default {
  components: {
    MembersListFilters,
    MemberListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  setup() {
    const MEMBER_APP_STORE_MODULE_NAME = 'app-member'

    // Register module
    if (!store.hasModule(MEMBER_APP_STORE_MODULE_NAME)) store.registerModule(MEMBER_APP_STORE_MODULE_NAME, memberStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MEMBER_APP_STORE_MODULE_NAME)) store.unregisterModule(MEMBER_APP_STORE_MODULE_NAME)
    })


    store
      .dispatch('app-member/fetchMembers')
      .then(response => {
        // const { members, total } = response.data

        // callback(members)
        // totalMembers.value = total
      })
      .catch((err) => {
        console.log(err)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching members list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    const isAddNewMemberSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Distant', value: 'distant' },
      { label: 'Not Active', value: 'not active' },
      { label: 'Active', value: 'active' },
      { label: 'Invalid', value: 'invalid' },
      { label: 'Deceased', value: 'deceased' },
    ]

    const genderOptions = [
      { label: 'Male', value: 'male' },
      { label: 'Female', value: 'female' },
    ]

    const daybornOptions = [
      { label: 'Sunday', value: 'sunday' },
      { label: 'Monday', value: 'monday' },
      { label: 'Tuesday', value: 'tuesday' },
      { label: 'Wednesday', value: 'wednesday' },
      { label: 'Thursday', value: 'thursday' },
      { label: 'Friday', value: 'friday' },
      { label: 'Saturday', value: 'saturday' },
    ]

    const maritalOptions = [
      { label: 'Single', value: 'single' },
      { label: 'Married', value: 'married' },
      { label: 'Seperated', value: 'seperated' },
      { label: 'Divorced', value: 'divorced' },
      { label: 'Widower', value: 'widower' },
    ]


    const {
      getMembers,
      fetchMembers,
      tableColumns,
      perPage,
      currentPage,
      totalMembers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMemberListTable,
      refetchData,
      getAllMembers,

      // UI
      resolveMemberRoleVariant,
      resolveMemberRoleIcon,
      resolveMemberStatusVariant,
      resolveGenderVariant,
      resolveMemberGenderVariant,
      resolveDaybornVariant,

      // Extra Filters
      daybornFilter,
      genderFilter,
      maritalFilter,
      statusFilter,
    } = useMemberList()

    setTimeout(() => {
      
      if (router.currentRoute.params.search && router.currentRoute.params.gender && router.currentRoute.params.dayborn && router.currentRoute.params.marital && router.currentRoute.params.status) {
        //
        genderFilter.value = router.currentRoute.params.gender
        daybornFilter.value = router.currentRoute.params.dayborn
        maritalFilter.value = router.currentRoute.params.marital
        statusFilter.value = router.currentRoute.params.status
        searchQuery.value = router.currentRoute.params.search

      } else if (router.currentRoute.params.search) {
        searchQuery.value = router.currentRoute.params.search

      }
    }, 500)

    return {

      // Sidebar
      isAddNewMemberSidebarActive,

      getMembers,
      fetchMembers,
      tableColumns,
      perPage,
      currentPage,
      totalMembers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMemberListTable,
      refetchData,
      getAllMembers,

      // Filter
      avatarText,

      // UI
      resolveMemberRoleVariant,
      resolveMemberRoleIcon,
      resolveMemberStatusVariant,
      resolveGenderVariant,
      resolveMemberGenderVariant,
      resolveDaybornVariant,

      genderOptions,
      daybornOptions,
      maritalOptions,
      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      daybornFilter,
      genderFilter,
      maritalFilter,
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
